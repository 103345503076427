import { autorun } from 'mobx';
import {
  CONTENT_ITEM_TYPES, ImageService, ONLINE_CONTENT_TYPES,
  assignmentManager, courseManager, reportIdentityManager,
  tagContentManager
} from 'sat-core';

ImageService.assignmentDisposer = null;
ImageService.aggregateAssignmentDisposer = null;
ImageService.courseDisposer = null;

ImageService.getImageUrl = (element) => {
  if (!element) {
    return;
  }
  const { isReport } = reportIdentityManager;
  const {
    contentItemId, contentType, courseImageUrl,
    elementContentItemImageUrl, elementType
  } = element;

  let contentImageName;
  if (isReport) {
    contentImageName = contentType;
  } else {
    contentImageName = tagContentManager.getCardContentTagName(contentItemId);
  }

  let imageUrl = null;
  if (contentImageName) {
    imageUrl = ONLINE_CONTENT_TYPES.getContentImageUrl(contentImageName);
  }

  if (!imageUrl) {
    imageUrl = CONTENT_ITEM_TYPES.getContentImageUrl(
      element.imageUrl || elementContentItemImageUrl ||
      (elementType === 'ROOT' ? courseImageUrl : undefined)
    );
  }
  return imageUrl;
};

const fetchTagsByCategoryAndContentItemsCache = {};
const fetchTagsByCategoryAndContentItems = async () => {
  if (tagContentManager.tagContentItemIds.length > 0) {
    const ids = tagContentManager.getTagContentItemIds();
    if (fetchTagsByCategoryAndContentItemsCache[ids]) return;
    fetchTagsByCategoryAndContentItemsCache[ids] = true;
    await tagContentManager.fetchTagsByCategoryAndContentItems(ids);
    delete fetchTagsByCategoryAndContentItemsCache[ids];
  }
};

const addTagId = (item) => {
  const contentItemId = item.contentItemId || item.resourceContentItemId;
  if (contentItemId !== null && contentItemId !== '' &&
    tagContentManager.tagContentItemIds.indexOf(contentItemId) === -1) {
    tagContentManager.addTagId(contentItemId);
  }
};

ImageService.updateTagContentManagerFromCourses = async () => {
  courseManager.courseTreeMap.forEach((coursesList) => coursesList.forEach((course) => addTagId(course))
  );

  courseManager.currentCourseElementList.forEach((courseElement) => {
    addTagId(courseElement);
  });

  await fetchTagsByCategoryAndContentItems();
};

ImageService.updateTagContentMangerFromAssignments = async () => {
  assignmentManager.assignments.forEach((assignment) => addTagId(assignment));
  await fetchTagsByCategoryAndContentItems();
};

ImageService.updateTagContentMangerFromAggregateAssignments = async () => {
  assignmentManager.aggregateAssignments.forEach((aggregateAssignment) => {
    if (aggregateAssignment) {
      addTagId(aggregateAssignment);
    }
  });
  await fetchTagsByCategoryAndContentItems();
};

ImageService.initImageService = () => {
  ImageService.assignmentDisposer = autorun(ImageService.updateTagContentMangerFromAssignments);
  ImageService.aggregateAssignmentDisposer = autorun(ImageService.updateTagContentMangerFromAggregateAssignments);
  ImageService.courseDisposer = autorun(ImageService.updateTagContentManagerFromCourses);
};

ImageService.cleanUp = () => {
  if (ImageService.assignmentDisposer !== null && ImageService.assignmentDisposer !== undefined) {
    ImageService.assignmentDisposer();
  }
  if (ImageService.courseDisposer !== null && ImageService.courseDisposer !== undefined) {
    ImageService.courseDisposer();
  }
  if (ImageService.aggregateAssignmentDisposer !== null && ImageService.aggregateAssignmentDisposer !== undefined) {
    ImageService.aggregateAssignmentDisposer();
  }
};
