import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AssignmentReportSettings, SatCoreRegister } from 'sat-core';

export default @inject('courseManager', 'assignmentManager', 'gradebookManager', 'classroomManager')
@observer
class AssignmentReportSettingsOverride extends Component {
  render() {
    const { currentAssignment } = this.props;

    return (
      <>
        <AssignmentReportSettings
          currentAssignment={currentAssignment}
          optionsCheckedByDefault={true}
          {...this.props} />
      </>
    );
  }
}

SatCoreRegister('AssignmentReportSettings', AssignmentReportSettingsOverride);
