import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import {
  Auth, FLOWPAPER_SESSION_KEYS, SatCoreComponent, assignmentManager, classroomManager,
  contentManager, courseDeliveryManager, courseManager, dialogManager, gradebookManager,
  i18nAddSatellite, navigationManager, reportOverrideManager, tagContentManager, userManager
} from 'sat-core';

import { API_ROOT, LESSON_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE } from './api-config';

import logo from './img/gallopade-logo-2012-w@2x.png';
import 'semantic-ui-less/semantic.less';
import './index.less';

/* Put override imports here */
import './components/AssignmentReportSettingsOverride';
import './services/ImageServiceOverride';
import * as serviceWorker from './serviceWorker';

import gallopadeTranslations from './gallopadeTranslations';

i18nAddSatellite('gallo', gallopadeTranslations);

assignmentManager.setEnableCourseSearch(true);
assignmentManager.setLateSubmissionOptionAllowed(true);
assignmentManager.setStudentAssignmentResourceTypeFilter(true);
assignmentManager.setUseAssignmentTabRollovers(true);
assignmentManager.setUseContentTypeSearch(true);
assignmentManager.setUseDateSearchType(true);
classroomManager.setEnabledStudentsOnly(false);
classroomManager.setHideAddExistingStudentButtonForTeachers(false);
contentManager.setFlowpaperSessionKeyStudent(FLOWPAPER_SESSION_KEYS.CRE_USER);
contentManager.setHelpWindowUrl('https://support.gallopade.com');
courseDeliveryManager.setAllowedResourcePillKeys(['interactive', 'autoScored', 'pdfPlus']); // note: order matters here, do not alphabetize
courseDeliveryManager.setResourcePacingToolbarItemKeysToExclude(['studentPaced', 'teacherAssign']);
courseManager.setAllowCustomCourses(true);
courseManager.setUseSpecialCustomCourseCardImages(true);
courseManager.setUseNoProductCourses(true);
gradebookManager.setAllowAssignmentLink(true);
gradebookManager.setAllowGradebookStandards(true);
gradebookManager.setAllowLearnosityGradebookDetail(true);
gradebookManager.setShouldUsePodiumIconForInstructions(true);
gradebookManager.setUseGradebookHeaderResourceInfo(true);
reportOverrideManager.setAllowCourseReportExpandableRowData(false);
reportOverrideManager.setAllowLessonFilterDropdown(true);
reportOverrideManager.setAllowUseDefaultImageSource(true);
reportOverrideManager.setShouldAppendCourseElementContentTypeAndElementDescriptionToReportTableRowTitle(true);
tagContentManager.setCustomCategoryNameA('Question Type');
tagContentManager.setCustomCategoryNameB('');
tagContentManager.setCustomCategoryNameC('Topic Name');
tagContentManager.setUseContentTags(true);
userManager.setClientPrefix('GALL');
userManager.setRestrictAvatar(true);
userManager.setShowTextHelpForAll(true);

const App = SatCoreComponent('App');
Auth.init(API_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE, logo, LESSON_ROOT, { SHOW_SHUFFLE_OVERRIDE: true });
Auth.overrideLessonPlayer = true;

ReactDOM.render(
  <Provider
    assignmentManager={assignmentManager}
    classroomManager={classroomManager}
    contentManager={contentManager}
    courseManager={courseManager}
    dialogManager={dialogManager}
    gradeBookManager={gradebookManager}
    navigationManager={navigationManager}
    userManager={userManager}>
    <App />
  </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
