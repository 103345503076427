/* eslint-disable max-len */
const translations = {
  AddCourseToClassModal: {
    addNewCustomCourse: '+ Add New ExperTrack Item Bank',
    customCourseZeroStateMsg: 'No content found. New item banks may take a moment to appear.',
    customCoursesTabName: 'Customizable Item Bank',
    modalAddNewCustomCourse: 'Create New ExperTrack Item Bank',
    publisherCoursesTabName: 'Gallopade Courses',
    selectCourses: 'Select Content'
  },
  AggregateAssignmentCard: {
    gradebookLabel: 'Submissions'
  },
  AssignmentCard: {
    gradebookLabel: 'Submissions'
  },
  AssignmentFilter: {
    dateRangeLabel: 'Due Date Range',
    dateRangeSectionLabel: 'Due Date',
    lockedIconText: ' Assignments with future start dates are considered “locked” until the start date arrives.'
  },
  AssignmentMenu: {
    SearchPlaceholder: 'Search Content Name'
  },
  AssignmentStatus: {
    Ready: 'Not Started'
  },
  AssignmentView: {
    AssignedAssignments: 'Not Started',
    InProgressAssignments: 'Started',
    assignedHoverText: 'There is at least one student who has not started working on an assignment using this resource.',
    gradedHoverText: 'At least one assignment using this resource has been graded.',
    inProgressHoverText: 'There is at least one student who is actively working on an assignment using this resource.',
    needsGradingHoverText: 'At least one assignment using this resource requires manual grading or teacher attention to move forward.'
  },
  AssignmentsPopup: {
    allAssignments: 'All Submissions for Class',
    gradebookLabel: 'Submissions'
  },
  Button: {
    addCourseButton: '+ Add Course / Item Bank'
  },
  ClassCourses: {
    BannerTitle: 'My Course',
    InitialBreadcrumb: 'My Course',
    InitialBreadcrumb_plural: 'My Courses',
    courseZeroStateMsg: 'No content added yet.'
  },
  ClassDetails: {
    BannerTitle: 'My Courses'
  },
  ContentAssignmentModal: {
    allAssignments: 'All Submissions for Class',
    gradebookLabel: 'Submissions'
  },
  ContentElementModal: {
    allAssignments: 'All Submissions for Class',
    gradebookLabel: 'Submissions'
  },
  CourseElementModal: {
    allAssignments: 'All Submissions for Class',
    gradebookLabel: 'Submissions'
  },
  CourseLayout: {
    allAssignments: 'All Submissions for Class',
    gradebookLabel: 'Submissions'
  },
  CourseTreeLeaf: {
    studentPreview: 'Preview'
  },
  DocReaderModal: {
    gradebookLabel: 'Submissions'
  },
  FileViewerModal: {
    gradebookLabel: 'Submissions'
  },
  Gradebook: {
    aggregateGradebook: 'All Submissions for Class',
    allAssignments: 'All Submissions for Class',
    gradebookLabel: 'Submissions'
  },
  GradebookDetailsTable: {
    activityNumber: 'ASGMT'
  },
  GradebookHeader: {
    aggregateGradebook: 'All Submissions for Class',
    assignButtonDisabledMessage: 'Select assignments to reassign.',
    assignButtonLabel: 'Assign'
  },
  GradebookTable: {
    activityNumber: 'ASGMT'
  },
  InfoModal: {
    ContentHeader: 'Resource Description and Instructional Strategies',
    WhenToUse: 'When to Use'
  },
  LearnosityPrintPreview: {
    gradebookLabel: 'Submissions'
  },
  LearnosityScoringModal: {
    gradebookLabel: 'Submissions'
  },
  MainView: {
    readOnlyGradebookCautionBannerMsg: 'As an Administrator, the submissions are read only. To make changes, consult with the teacher or switch roles if you also have teacher permissions.'
  },
  NavigationService: {
    InitialBreadcrumb: 'My Course',
    InitialBreadcrumb_plural: 'My Courses',
    aggregateGradebook: 'All Submissions for Class',
    courseReport: 'Gradebook Report',
    gradebookLabel: 'Submissions'
  },
  ProgressView: {
    BannerTitle: 'My Progress'
  },
  ReportBreadcrumbService: {
    courseReport: 'Gradebook Report'
  },
  ReportCaption: {
    '/reports/course-classroom-detail': 'Gradebook Report - Class Detail (By Student)',
    '/reports/course-classroom-individual': 'Gradebook Report - {{studentFullName}}',
    '/reports/course-classroom-summary': 'Gradebook Report - Class Summary',
    '/reports/course-district-detail': 'Gradebook Report - District Detail (By School)',
    '/reports/course-district-summary': 'Gradebook Report - District Summary',
    '/reports/course-school-detail': 'Gradebook Report - School Detail (By Class)',
    '/reports/course-school-summary': 'Gradebook Report - School Summary'
  },
  ReportTypeSelectorView: {
    courseReport: 'Gradebook Report'
  },
  ResourcePacingModal: {
    reportsAndGrading: 'Reports & Grading'
  },
  ResourcePacingToolbar: {
    assignedPrefix: 'Submissions'
  },
  StudentAssignmentView: {
    DefaultSortOrder: 'desc'
  },
  StudentContentCardManager: {
    studentView: 'Preview'
  },
  StudentDashboard: {
    BookLabel: 'My Course'
  },
  StudentTopNav: {
    BookLabel: 'My Course'
  },
  TeacherDashboard: {
    gradebookLabel: 'Submissions'
  }
};

export default translations;
